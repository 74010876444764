<template>
  <div>
    <div class="header">
      <div class="btn-img" @click="logout">
        <img src="@/assets/icon-singout.png"/>
      </div>
    </div>
    <div class="page-title">Menu</div>
    <div class="config">
      <img src="@/assets/icon-settings.png" @click="link('config')"/>
    </div>    
    <div
      v-show="!auth.hasLicense"
      class="message">
      <img src="@/assets/icon-alert.svg"/>
      マイページでライセンスを
      <a @click="purchaseLicense">購入</a>
      または
      <a @click="registLicense">登録</a>
      してください。
    </div>
    <div class="menu-row mt-2">
      <div
        :class="{disabled: !hasLicense}"
        :disabled="!hasLicense"
        @click="link('steps?redirectTo=lessons', !hasLicense)">
        <img src="@/assets/menu-lessons.png"/>
      </div>
      <div
        :class="{disabled: !hasLicense}"
        @click="link('steps?redirectTo=exams', !hasLicense)">
        <img src="@/assets/menu-trial.png"/>
      </div>
    </div>
    <div class="menu-row mt-2">
      <div
        @click="link('lessonResultType', false)">
        <img src="@/assets/menu-results.png"/>
      </div>
      <div
        @click="mypage">
        <img src="@/assets/menu-mypage.png"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Term',
  props: {
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['auth',]),
    prontestSeriesUrl() {
      return process.env.VUE_APP_SERIES_URL
    },
    hasLicense() {
      return this.auth.hasLicense
    },
  },
  methods: {
    link(name, disable) {
      if (!disable) this.$router.push(name)
    },
    purchaseLicense() {
      const pageUrl = encodeURIComponent(`purchase.html?id=${this.auth.toeflAppId}`)
      location.href = `${this.prontestSeriesUrl}redirect.html?refreshToken=${this.auth.refreshToken}&page=${pageUrl}`
    },
    registLicense() {
      location.href = `${this.prontestSeriesUrl}redirect.html?refreshToken=${this.auth.refreshToken}&page=license.html`
    },
    mypage() {
      location.href = `${this.prontestSeriesUrl}redirect.html?refreshToken=${this.auth.refreshToken}&page=mypage.html`
    },
    logout() {
      this.$store.commit('clearAuthInfo')
      this.$router.push('/login')
    },
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: block;
  align-items: center;
}
.logout {
  flex: 3;
}
.page-title {
  flex: 5;
}
.config {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  margin: auto;
  margin-top: 40px;
  img {
    cursor: pointer;
    height: 100px;
  }
  text-align: center;
  font-size: 20px;
}
.message {
  height: 30px;
  font-size: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  a {
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    margin: 0 10px;
  }
}
.disabled {
  background: #c4c4c4;
}
.rect-menu {
  img {
    width: 100%;
  }
}

.menu-row {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  img {
    width: 300px;
    cursor: pointer;
  }
}
</style>

